var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"custom-header"},[(_vm.$currentUserCan(_vm.$permissions.PERM_CREATE_FILES))?_c('base-button',{staticClass:"kw-button add",attrs:{"icon":"","size":"sm"},on:{"click":_vm.openAddFileModal}},[_c('span',{staticClass:"btn-inner--icon"},[_c('i',{staticClass:"fal fa-plus"})]),_c('span',{staticClass:"btn-inner--text"},[_vm._v(" "+_vm._s(_vm.$t("FILES.ADD_FILE"))+" ")])]):_vm._e()],1),(_vm.object.id)?_c('file-list-table',{key:_vm.renderKey,attrs:{"filterObjectId":_vm.object.id,"filterObjectType":_vm.object.type},on:{"onEditFile":_vm.openEditFileModal,"onDeleteFile":_vm.deleteFile,"onViewFile":_vm.openViewFileModal}}):_vm._e(),(_vm.object.id)?_c('file-add-form-modal',{attrs:{"showModal":_vm.showAddFormModal,"object":_vm.object},on:{"onCloseModal":_vm.closeModal}}):_vm._e(),(_vm.object.id)?_c('file-edit-form-modal',{attrs:{"showModal":_vm.showEditFormModal,"fileData":_vm.file},on:{"onCloseModal":_vm.closeModal}}):_vm._e(),(_vm.object.id)?_c('file-view-modal',{attrs:{"showModal":_vm.showViewModal,"fileData":_vm.file},on:{"onCloseModal":_vm.closeModal,"fileUpdated":_vm.refreshFile,"editFile":(id) => {
        _vm.closeModal(false);
        _vm.openEditFileModal(id);
      },"deleteFile":(id) => {
        _vm.closeModal(false);
        _vm.deleteFile(id);
      }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }