<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ salesPayment.name }}</h3>
    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row">
          <dt>{{ $t("COMMON.DATE") }}</dt>
          <dd>
            {{ $formatDate(salesPayment.date) }}
          </dd>
        </dl>
        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_INVOICES)"
        >
          <dt>{{ $t("COMMON.INVOICE") }}</dt>
          <dd>
            <object-link :object="salesPayment.invoice" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd>
            <locations :locations="salesPayment.allowedLocations" />
          </dd>
        </dl>

        <dl
          class="row"
          v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
        >
          <dt>{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd>
            <organization :organization="salesPayment.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.STATUS") }}</dt>
          <dd>
            <salesPayment-status-badge :salesPayment="salesPayment" />
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.AMOUNT") }}</dt>
          <dd>
            {{ $formatCurrency(salesPayment.amount) }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("SALES_PAYMENTS.SOURCE") }}</dt>
          <dd>
            {{
              $t(`SALES_PAYMENTS.SALES_PAYMENT_SOURCE_${salesPayment.source}`)
            }}
          </dd>
        </dl>

        <dl class="row" v-if="salesPayment.excerpt">
          <dt>
            {{ $t("COMMON.EXCERPT") }}
          </dt>
          <dd>
            <div v-html="salesPayment.excerpt"></div>
          </dd>
        </dl>

        <dl class="row" v-if="salesPayment.transaction_id">
          <dt>
            {{ $t("SALES_PAYMENTS.TRANSACTION_ID") }}
          </dt>
          <dd>
            {{ salesPayment.transaction_id }}
          </dd>
        </dl>

        <dl class="row" v-if="salesPayment.transaction_data">
          <dt>
            {{ $t("SALES_PAYMENTS.TRANSACTION_DATA") }}
          </dt>
          <dd>
            {{ salesPayment.transaction_data }}
          </dd>
        </dl>

        <dl class="row" v-if="salesPayment.created_at">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ $formatDate(salesPayment.created_at) }}
          </dd>
        </dl>

        <dl class="row" v-if="salesPayment.updated_at">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ $formatDate(salesPayment.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>
<script>
import SalesPaymentStatusBadge from "./SalesPaymentStatusBadge.vue";
export default {
  name: "salesPayment-view-global",

  components: { SalesPaymentStatusBadge },

  props: ["salesPayment"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    salesPayment(salesPayment) {},
  },
};
</script>
