<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <div class="page-wrapper-table-header-left">
          <base-input
              v-model="query"
              type="search"
              class="search"
              prepend-icon="fas fa-search"
              :placeholder="$t('COMMON.SEARCH')"
              clearable
          />

          <organization-selector
              v-if="
            !filterOrganization &&
            !filterInvoice &&
            $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
          "
              @organizationChanged="
            (organizationId) => (selectedOrganization = organizationId)
          "
          />

          <base-input
              v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
              :placeholder="`${$t('COMMON.LOCATIONS')}`"
          >
            <locations-selector
                @locationsChanged="(locations) => (selectedLocations = locations)"
            />
          </base-input>

          <base-input class="dates" placeholder="Dates">
            <flat-picker
                :config="{
                allowInput: true,
                mode: 'range',
                locale: flatPickrLocale,
              }"
                class="form-control datepicker"
                v-model="dateRange"
                placeholder="Dates"
            >
            </flat-picker>
          </base-input>

        </div>
        <div class="page-wrapper-table-header-right">
          <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              :placeholder="$t('COMMON.PER_PAGE')"
          >
            <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="page-wrapper-table-body">
        <div class="table-loading" slot="empty" v-if="loading">
          <img src="/img/loading.gif" />
        </div>

        <div class="page-wrapper-table-body-inner" v-bind:style="{ visibility: loading ? 'hidden' : 'visible' }">
          <el-table
              class="align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="salesPayments"
              :empty-text="$t('COMMON.NO_SEARCH_RESULT')"
              @sort-change="sortChange">
            <el-table-column
                prop="code"
                min-width="220"
                sortable="custom"
                :label="$t('SALES_PAYMENTS.SALES_PAYMENTS_CODE_LABEL')"/>

            <!-- code facture -->
            <el-table-column
                v-if="!filterInvoice"
                :label="$t('SALES_INVOICES.SALES_INVOICES_CODE_LABEL')"
                prop="invoice.code"
                min-width="220"
            >
              <template v-slot="{ row }">
                <router-link :to="$objectViewRoute(row.invoice.id)">
                  {{ row.invoice ? row.invoice.code : null }}
                </router-link>
              </template>
            </el-table-column>

            <!-- client -->
            <el-table-column :label="$t('COMMON.CUSTOMER')" min-width="220">
              <template v-slot="{ row }">
                <object-link :object="row.invoice.recipient"/>
                <div>
                  {{ row.invoice.recipient.email }}
                </div>
              </template>
            </el-table-column>

            <!-- montant -->
            <el-table-column
                :label="$t('COMMON.AMOUNT')"
                prop="amount"
                min-width="220"
            >
              <template v-slot="{ row }">
                {{ $formatCurrency(row.amount) }}
              </template>
            </el-table-column>

            <!-- date et heure -->
            <el-table-column
                :label="$t('COMMON.DATE_TIME')"
                prop="date"
                sortable="date"
                min-width="220"
            >
              <template v-slot="{ row }">
                {{ $formatDate(row.date, "llll") }}
              </template>
            </el-table-column>

            <!-- locations -->
            <el-table-column
                :label="$t('COMMON.LOCATIONS')"
                sortable="custom"
                v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
                min-width="220"
            >
              <template v-slot="{ row }">
                <locations :locations="row.allowedLocations" />
              </template>
            </el-table-column>

            <!-- organizations -->
            <el-table-column
                v-if="
                !filterOrganization &&
                !filterInvoice &&
                $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
              "
                :label="$t('COMMON.ORGANIZATION')"
                prop="organization"
                min-width="220"
            >
              <template v-slot="{ row }">
                <organization :organization="row.organization" />
              </template>
            </el-table-column>

            <!-- status -->
            <el-table-column
                :label="$t('COMMON.STATUS')"
                prop="status"
                min-width="200"
            >
              <template v-slot="{ row }">
                <salesPayment-status-badge :salesPayment="row" />
              </template>
            </el-table-column>

            <!-- creer le -->
            <el-table-column
                :label="$t('COMMON.CREATED_AT')"
                prop="created_at"
                sortable="custom"
                min-width="220">
              <template v-slot="{ row }">
                {{ $formatDate(row.created_at, "llll") }}
              </template>
            </el-table-column>

            <!-- actions -->
            <el-table-column fixed="right" min-width="120">
              <div slot-scope="{ row }" class="table-actions">
                <el-tooltip
                    :content="$t('COMMON.VIEW')"
                    placement="top"
                    v-if="$currentUserCan($permissions.PERM_VIEW_SALES_PAYMENTS)"
                >
                  <a
                      type="text"
                      @click="viewSalesPayment(row)"
                      class="table-action"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                  >
                    <i class="fal fa-expand-alt"></i>
                  </a>
                </el-tooltip>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div v-if="salesPayments && salesPayments.length > 0" slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}

          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import { INVOICE_STATUS_SENT } from "@/constants/invoices";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import SalesPaymentStatusBadge from "./SalesPaymentStatusBadge.vue";
import { French } from "flatpickr/dist/l10n/fr.js";
import flatPicker from "vue-flatpickr-component";
import LoadingPanel from "@/components/LoadingPanel.vue";

export default {
  name: "salesPayment-list-table",

  components: {
    LoadingPanel,
    flatPicker,
    BasePagination,
    OrganizationSelector,
    SalesPaymentStatusBadge,
    LocationsSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterInvoice: {
      type: String,
      default: null,
      description: "Invoice id",
    },
  },

  data() {
    return {
      flatPickrLocale: French,
      query: null,
      selectedRows: [],
      sort: "-date",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      salesPayments: [],
      INVOICE_STATUS_SENT: INVOICE_STATUS_SENT,
      loading: true,
      dateRange: null,
      selectedOrganization: null,
      selectedLocations: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterInvoice: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
    dateRange: {
      handler: "getListDebounced",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList()
    {
      try
      {
        this.loading = true;

        const vDateRange = this.dateRange
            ? this.dateRange.split(this.flatPickrLocale.rangeSeparator)
            : [];

        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { code: this.query } : {}),
            ...(vDateRange.length == 2
                ? {
                  created_at: [
                    vDateRange[0] + " 00:00:00",
                    vDateRange[1] + " 23:59:59",
                  ],
                }
                : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,invoice,invoice.recipient,allowedLocations",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }
        if (this.filterInvoice) {
          params = {
            ...params,
            filter: { ...params.filter, invoice: this.filterInvoice },
          };
        }
        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }

        await this.$store.dispatch("salesPayments/list", params);
        this.salesPayments = this.$store.getters["salesPayments/list"];
        this.total = this.$store.getters["salesPayments/listTotal"];

      }
      catch (error)
      {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
      finally {
        this.loading = false;
      }
    },

    viewSalesPayment(salesPayment) {
      this.$emit("onViewSalesPayment", salesPayment);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
